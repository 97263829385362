const Timesheet = {
  id: null,
  user_id: null,
  year: '',
  month: '',
  total: 0,
  days: [],
  type: 'full-time',
};

export default Timesheet
