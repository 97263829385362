<template>
  <div class="container-fluid">
    <div class="row mb-3">
      <div class="col-12">
        <octo-header-button :buttons="headerButtons" @onSaveTimesheet="checkTimesheet" :title="headerTitle"/>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-12 col-md-6">
        <card body-classes="standard-card-body" class="shadow">
          <ul class="list-group list-group-flush">
            <list-group-item-component :label="$t('callcenter.type')">
              <template slot="value">
                <base-input class="mb-0">
                  <el-select
                    class="select-default text-uppercase"
                    :placeholder="$t('fields.choose')"
                    v-model="timesheet.type"
                  >
                    <el-option
                      v-for="option in [
                  { value: 'full-time', label: 'full-time'},
                  { value: 'part-time', label: 'part-time'},
                ]"
                      class="select-default text-uppercase w-100"
                      :value="option.value"
                      :label="option.label"
                      :key="option.value"
                    >
                    </el-option>
                  </el-select>
                </base-input>
              </template>
            </list-group-item-component>
          </ul>
        </card>
      </div>

      <div class="col-12 col-md-6">
        <card body-classes="standard-card-body" class="shadow">
          <ul class="list-group list-group-flush">
            <list-group-item-component
              :label="$t('callcenter.total_hours')">
              <template slot="value">
                <h2 class="mb-0">
                  <label-theme-component>{{ totalHours }} h</label-theme-component>
                </h2>
              </template>
            </list-group-item-component>
          </ul>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-12">
        <card body-classes="standard-card-body" class="shadow">
          <ValidationObserver ref="hourForm">
            <div class="row">
              <div class="col-12 col-md-4" v-for="(items, indexChunked) in daysChunked"
                   v-bind:key="`chunk-${indexChunked}`">
                <ul class="list-group list-group-flush">
                  <li class="standard-li" v-for="(day, index) in items" v-bind:key="`day-${index}`">
                    <div class="standard-label"
                         :class="(day.dayOfWeek === 0 || day.dayOfWeek === 6) ? 'text-primary' : ''">
                      {{ $t('common.' + weekDays[day.dayOfWeek]) }} {{ day.dayNumber }}
                    </div>
                    <ValidationProvider
                      name="hour"
                      :vid="`hour-${index}-${indexChunked}`"
                      rules="numeric|between:0,24"
                      v-slot="{ passed, failed, errors }"
                    >
                      <base-input
                        maxlength="2"
                        class="day-input mb-0"
                        :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                        v-model="day.hours"/>
                    </ValidationProvider>
                  </li>
                </ul>
              </div>
            </div>
          </ValidationObserver>
        </card>
      </div>
    </div>
  </div>
</template>

<script>

import {Select, Option} from "element-ui"
import OctoIcon from "@/components/octo-icon/OctoIcon";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import {endpoints} from "@/routes/endpoints";
import Timesheet from "@/models/timesheet";
import BaseAlert from "../../components/BaseAlert";
import UserWidget from "@/components/user-widget/UserWidget";
import {mapGetters} from "vuex";
import User from "@/models/user";
import {TabPane, Tabs} from "@/components";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";


export default {
  name: "TimesheetOperatorMonthlyPage",
  components: {
    LabelThemeComponent,
    ListGroupItemComponent,
    UserWidget,
    BaseAlert,
    OctoHeaderButton,
    OctoIcon,
    Tabs,
    TabPane,
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      year: this.$route.params.year || this.$moment().year(),
      month: this.$route.params.month || this.$moment().month().padStart(2, '0'),
      operatorId: this.$route.params.id,
      timesheet: this.$_.cloneDeep(Timesheet),
      user: this.$_.cloneDeep(User),
      headerButtons: [
        {
          label: 'save',
          onClick: 'onSaveTimesheet',
        }
      ],
      weekDays: {
        0: 'sun',
        1: 'mon',
        2: 'tue',
        3: 'wed',
        4: 'thu',
        5: 'fri',
        6: 'sat'
      }
    }
  },
  computed: {
    ...mapGetters({
      users: 'common/users'
    }),
    daysChunked: function () {
      return this.$_.chunk(this.timesheet.days, 11)
    },
    totalHours: function () {
      let total = 0;

      this.$_.each(this.timesheet.days, day => {
        total += Number(day.hours);
      });

      return total;
    },

    headerTitle() {
      return this.user?.firstname + ' ' + this.user?.lastname + ' - ' + this.month + '/' + this.year;
    }
  },
  async mounted() {
    try {
      this.$fullLoading.show();
      this.timesheet.year = this.$route.params.year
      this.timesheet.month = this.$route.params.month
      this.timesheet.user_id = this.$route.params.id

      const responseTimesheet = await this.$api.get(
        endpoints.CALL_CENTER_TIMESHEET_OPERATOR_MONTHLY_SHOW
          .replace('{id}', this.timesheet.user_id)
          .replace('{year}', this.timesheet.year)
          .replace('{month}', this.timesheet.month)
      );

      this.timesheet.type = responseTimesheet?.data?.type || 'full-time';
      this.timesheet.days = responseTimesheet?.data?.days || [];

      this.user = this.$_.find(this.users, (user) => {
        return user.id === parseInt(this.timesheet.user_id)
      });

      this.$fullLoading.hide();
    } catch (e) {
      this.$fullLoading.hide();
      this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
    }
  },
  methods: {

    checkTimesheet: function () {

      this.$refs.hourForm.validate()
        .then((success) => {
          if (!success) {
            this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
          } else {
            this.saveTimesheet();
          }
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        });

    },

    saveTimesheet: async function () {
      try {
        this.$fullLoading.show();

        await this.$api.post(
          endpoints.CALL_CENTER_TIMESHEET_OPERATOR_MONTHLY_STORE
            .replace('{id}', this.timesheet.user_id)
            .replace('{year}', this.timesheet.year)
            .replace('{month}', this.timesheet.month),
          {days: this.timesheet.days, type: this.timesheet.type}
        );

        this.$fullLoading.hide();
        this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
      } catch (e) {
        console.log(e);
        this.$fullLoading.hide();
        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
      }
    },
  }
}
</script>

<style scoped lang="scss">
.day-input {
  width: 80px;
}
</style>
