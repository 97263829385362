<template>
  <card class="shadow" body-classes="standard-card-body">
    <div slot="header">
      <h4 class="card-title text-capitalize">{{ $t('page.user_data') }}</h4>
    </div>
    <ul class="list-group list-group-flush">
      <list-group-item-component
        :value="user | optional('firstname')"
        :label="$t('fields.firstname')" />
      <list-group-item-component
        :value="user | optional('lastname')"
        :label="$t('fields.lastname')" />
      <list-group-item-component
        :value="user.username"
        :label="$t('fields.username')" />
    </ul>
  </card>
</template>

<script>
import User from "@/models/user";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";

export default {
  name: "UserWidget",
  components: {ListGroupItemComponent},
  props: {
    user: {
      type: Object,
      default: () => this.$_.cloneDeep(User)
    }
  }
}
</script>

<style scoped>

</style>
